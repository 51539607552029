import './diamonds.scss';

import React, { ReactNode } from 'react';
import classnames from 'classnames';

import HoneycombDiamond from './HoneycombDiamond';
import { useHasBeenOnScreen } from '../../hooks';

interface HoneycombDiamondProps {
  label: ReactNode;
  icon: ReactNode;
  backgroundImage: string;
  linkTo?: string;
}

interface Props {
  diamonds: [ HoneycombDiamondProps, HoneycombDiamondProps, HoneycombDiamondProps, HoneycombDiamondProps, HoneycombDiamondProps ]
}

const HoneycombDiamonds = ({ diamonds }: Props) => {
  const divRef = React.useRef(null);
  const hasBeenOnScreen = useHasBeenOnScreen(divRef);

  return (
    <div ref={divRef} className="diamonds">
      <ul className="diamonds">
        <HoneycombDiamond className={classnames('row-2', { 'on-screen': hasBeenOnScreen })} diamondNumber={1} {...diamonds[0]} />
        <HoneycombDiamond className={classnames('row-1', { 'on-screen': hasBeenOnScreen })} diamondNumber={2} {...diamonds[1]} />
        <HoneycombDiamond className={classnames('row-2', { 'on-screen': hasBeenOnScreen })} diamondNumber={3} {...diamonds[2]} />
        <HoneycombDiamond className={classnames('row-1', { 'on-screen': hasBeenOnScreen })} diamondNumber={4} {...diamonds[3]} />
        <HoneycombDiamond className={classnames('row-2', { 'on-screen': hasBeenOnScreen })} diamondNumber={5} {...diamonds[4]} />
      </ul>
    </div>
  );
};

export default HoneycombDiamonds;
