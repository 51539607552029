import './hero-diamond.scss';

import React, { ReactNode } from 'react';

interface Props {
  backgroundSrc: string;
  foregroundSrc: string;
  foreground: ReactNode;
  background: ReactNode;
}

const HeroDiamond = ({ foregroundSrc, backgroundSrc, foreground, background }: Props) => {
  return (
    <div className="hero-diamond-container">
      <div className="image-container">
        <img className="background" src={backgroundSrc} alt="Aviation Safety" />
        <img className="foreground" src={foregroundSrc} alt="Aviation Safety" />
      </div>

      <div className="foreground-text">
        {foreground}

      </div>

      <div className="background-text">
        {background}
      </div>
    </div>
  );
};

export default HeroDiamond;
