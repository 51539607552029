import React from 'react';
import BackgroundImage from '../BackgroundImage/BackgroundImage';

interface Props {
  thumbnailSrc?: string;
  title: string;
  date: string;
  content: string;
  link?: string;
}

const NewsItem = ({ title, link, date, thumbnailSrc }: Props) => {
  const Tag = link ? 'a' : 'div';
  const tagProps = link ? { href: link, target: '_blank', rel: 'noopener noreferrer' } : {};
  return (
    <div className="mx-1 px-3 py-5">
     <div className="d-flex flex-column align-items-center">
       <Tag className="d-flex align-items-center justify-content-center news-item mb-3 position-relative" {...tagProps}>
         {thumbnailSrc && <BackgroundImage src={thumbnailSrc} alt={`Thumbnail for ${title}`} style={{ width: 200, height: 200 }} />}
       </Tag>

       <h4 className="mb-0">{date}</h4>
       <h4>{title}</h4>
     </div>
    </div>
  );
};

export default NewsItem;
