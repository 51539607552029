import React, { useMemo } from 'react';

import { Button, Col, Container, Row } from 'reactstrap';
import Lottie from "lottie-react";
import { Options as SplideOptions, Splide, SplideSlide } from '@splidejs/react-splide';
import { AnimateIn, ClickableDiv, FlexColumn, FlexRow, Navbar } from '../components';
import logoAnimation from '../animations/logo.json';
import HoneycombDiamonds from '../components/Diamonds/HoneycombDiamonds';
import BaseLayout from '../components/Layouts/BaseLayout';
import PlaneIcon from '../components/Icons/PlaneIcon';
import GlobeIcon from '../components/Icons/GlobeIcon';
import NewsItem from '../components/NewsItem/NewsItem';
import useWindowBreakpoints from '../hooks/useWindowBreakpoints';
import HeroDiamond from '../components/HeroDiamond/HeroDiamond';
import classnames from 'classnames';
import useAutoplayAudio from '../hooks/useAutoplayAudio';
import LargeBar from '../components/Bars/LargeBar';
import SmallBar from '../components/Bars/SmallBar';
import useNavigationScroller from '../hooks/useNavigationScroller';

import homeDataJson from '../data/home.json';
import Markdown from '../components/Markdown/Markdown';
import MarqueeDivider from '../components/MarqueeDivider/MarqueeDivider';
import { Link } from 'gatsby';
import PartnersFooter from '../components/PartnersFooter/PartnersFooter';
import useCanAutoplay from '../hooks/useCanAutoplay';

const DiamondGlobeIcon = <GlobeIcon width={50} />
const DiamondPlaneIcon = <PlaneIcon width={40} />

export const IndexPageInternal = (homeData: typeof homeDataJson) => {
  const wrapper = React.useRef<HTMLDivElement>(null);
  useNavigationScroller(wrapper);
  const [isMuted, toggleAudioMute] = useAutoplayAudio('/assets/audio/heroic.mp3');
  const windowBreakpoints = useWindowBreakpoints();
  const canAutoplay = useCanAutoplay();

  const splideOptions = useMemo((): Partial<SplideOptions> => {
    const perPage = (() => {
      if(windowBreakpoints.xs || windowBreakpoints.sm || windowBreakpoints.md) {
        return 1;
      }

      if(windowBreakpoints.lg) {
        return 3;
      }

      return 3;
    })();
    return {
      rewind: true,
      type: 'loop',
      perPage: perPage,
      perMove: 1
    }
  }, [windowBreakpoints]);

  return (
    <BaseLayout
      title={undefined}
    >
      <Navbar />

      <div className="wrapper" ref={wrapper}>
        <div className="bg-primary position-relative overflow-hidden p-3" style={{ height: '100vh' }}>

          <FlexColumn className="hero-content" justifyAround>
            <div className="text-center">
              <Link to="/">
                <img className="img-fluid hero-opacity" src="/assets/img/logo-text.svg" alt="Aviation Safety Solutions" style={{ height: '10vh' }} />
              </Link>
            </div>

            <FlexRow justifyAround overflowHidden>
              <div className="diamond-column">
                <HeroDiamond
                  foregroundSrc={homeData.hero.aviationForeground}
                  backgroundSrc={homeData.hero.aviationBackground}
                  foreground={(
                    <div className="text-center">
                      Aviation<br />Safety
                    </div>
                  )}
                  background={(
                    <>
                      <div>
                        <Link to="/auditingServices">Auditing Services</Link>
                      </div>
                      <div>
                        <Link to="/sms">SMS Implementation</Link>
                      </div>
                      <div>
                        <Link to="/safetyAssistance">Safety Assistance Program</Link>
                      </div>
                      <div>
                        <Link to="/safetyTraining">Safety Training Academy</Link>
                      </div>
                      <div>
                        <Link to="/consulting">Consulting Services</Link>
                      </div>
                    </>
                  )}
                />
              </div>

              <div className="hero-opacity logo-animation">
                <Lottie animationData={logoAnimation} loop={true} />
              </div>

              <div className="diamond-column">
                <HeroDiamond
                  foregroundSrc={homeData.hero.technologyForeground}
                  backgroundSrc={homeData.hero.technologyBackground}
                  foreground={(
                    <div className="text-center mt-3">
                      Transportation<br />& Technology<br /> Safety
                    </div>
                  )}
                  background={(
                    <>
                      <div>
                        <Link to="/safetyConsulting">Safety Consulting</Link>
                      </div>
                      <div>
                        <Link to="/internalAuditing">Internal Auditing</Link>
                      </div>
                      <div>
                        <Link to="/manualDevelopment">Manual Development</Link>
                      </div>
                      <div>
                        <Link to="/customizedSms">Customized SMS</Link>
                      </div>
                      <div>
                        <Link to="/trainingDevelopment">Training Development</Link>
                      </div>
                    </>
                  )}
                />
              </div>
            </FlexRow>

            <div className="slogan-container">
              <img className="img-fluid hero-opacity" src={require('../images/slogan.svg').default} alt="Achieving Higher Standards Together" />
            </div>
          </FlexColumn>

          <video className="hero-video" autoPlay={canAutoplay} loop muted>
            <source
              src="/assets/video/hero-medium-2.mp4"
              type="video/mp4"
            />
          </video>

          <div className="audio-container p-3 text-white hero-opacity">
            <ClickableDiv onClick={toggleAudioMute}>
              <i className={classnames('mdi mdi-36px', { 'mdi-volume-high': !isMuted, 'mdi-volume-off': isMuted })} />
            </ClickableDiv>
          </div>
        </div>

        <div className="body-bg">
          <AnimateIn tag="section" className="pt-5 body-bg">
            <Container className="text-center">
              <h1 className="heading text-uppercase mb-0">{homeData.heading.mainHeader1}</h1>
              <div className="hero-opacity my-1">
                <LargeBar style={{ width: '60%' }} />
              </div>
              <h1 className="heading text-uppercase mb-5">{homeData.heading.mainHeader2}</h1>
              <h2 className="mb-0 text-uppercase text-accent m-4 mt-5">Welcome to</h2>
              <h1 className="heading mb-4">Aviation Safety Solutions, Inc.</h1>
              <Markdown source={homeData.heading.paragraph1} />
            </Container>
          </AnimateIn>

          <MarqueeDivider />

          <AnimateIn tag="section" className="body-bg">
            <Container className="text-center">
              <Markdown source={homeData.heading.paragraph2} />

              <Row className="mt-5 mt-md-0">
                <Col md={6} className="text-center d-flex align-items-center justify-content-center">
                  <FlexColumn className="h-100" justifyAround>
                    <div>
                      <h2 className="text-accent mb-4">We help you</h2>

                      {
                        homeData.weHelpYou.map((section, i) => (
                          <React.Fragment key={i}>
                            <h1 className="heading text-uppercase my-2">{section.label}</h1>
                            {i !== homeData.weHelpYou.length - 1 && (
                              <div className="hero-opacity my-1">
                                <SmallBar style={{ width: '60%' }} />
                              </div>
                            )}
                          </React.Fragment>
                        ))
                      }
                    </div>

                    <Row>
                      <Col>
                        <Link className="hero-opacity btn btn-secondary" to="/inquire">Inquire</Link>
                      </Col>
                      <Col>
                        <a href="https://app.avsafetysolutions.com/" target="_blank" rel="noopener noreferrer" className="btn btn-secondary hero-opacity">Log In</a>
                      </Col>
                    </Row>
                  </FlexColumn>
                </Col>
                <Col md={6} className="mt-2 mt-md-0">
                  <FlexColumn className="p-5 text-right align-items-center">
                    <div style={{ width: 200 }}>
                      <img className="text-accent" src={require('../assets/img/skyla.svg').default} />
                    </div>
                    <div className="w-100">
                      <img src={require('../assets/img/world.svg').default} />
                    </div>
                  </FlexColumn>
                </Col>
              </Row>
            </Container>
          </AnimateIn>
        </div>


        <div className="position-relative">
          <div className="ParallaxVideo">
            <video autoPlay={canAutoplay} muted loop>
              <source src="/assets/video/section-cloud.mp4" type="video/mp4"/>
            </video>
          </div>

          <AnimateIn tag="section" className="pt-5 overflow-hidden position-relative">
            <Container className="text-center">
              <h1 className="heading my-0">Aviation Safety<br/>Services</h1>

              <div className="hero-opacity my-2">
                <SmallBar style={{ width: '30%' }} />
              </div>

              <div className="position-relative w-100">
                <HoneycombDiamonds
                  diamonds={[
                    { label: 'Auditing Services', icon: DiamondPlaneIcon, backgroundImage: homeData.aviationDiamonds.diamond5, linkTo: '/auditingServices' },
                    { label: 'Consulting Services', icon: DiamondPlaneIcon, backgroundImage: homeData.aviationDiamonds.diamond1, linkTo: '/consulting' },
                    { label: 'Safety Assistance Program', icon: DiamondPlaneIcon, backgroundImage: homeData.aviationDiamonds.diamond2, linkTo: '/safetyAssistance' },
                    { label: 'SMS Implementation', icon: DiamondPlaneIcon, backgroundImage: homeData.aviationDiamonds.diamond3, linkTo: '/sms' },
                    { label: 'Safety Training Academy', icon: DiamondPlaneIcon, backgroundImage: homeData.aviationDiamonds.diamond4, linkTo: '/safetyTraining' },
                  ]}
                />
              </div>
            </Container>
          </AnimateIn>

          <section className="pt-5 overflow-hidden position-relative">
            <Container className="text-center">
              <h1 className="heading my-0">Transportation<br/>& Technology<br/> Safety</h1>

              <div className="hero-opacity my-2">
                <SmallBar style={{ width: '30%' }} />
              </div>

              <div className="position-relative w-100">
                <HoneycombDiamonds
                  diamonds={[
                    { label: 'Manual Development', icon: DiamondGlobeIcon, backgroundImage: homeData.technologyDiamonds.diamond1, linkTo: '/manualDevelopment' },
                    { label: 'Safety Consulting', icon: DiamondGlobeIcon, backgroundImage: homeData.technologyDiamonds.diamond2, linkTo: '/safetyConsulting' },
                    { label: 'Customized SMS', icon: DiamondGlobeIcon, backgroundImage: homeData.technologyDiamonds.diamond3, linkTo: '/customizedSms' },
                    { label: 'Internal Auditing', icon: DiamondGlobeIcon, backgroundImage: homeData.technologyDiamonds.diamond4, linkTo: '/internalAuditing' },
                    { label: 'Training Development', icon: DiamondGlobeIcon, backgroundImage: homeData.technologyDiamonds.diamond5, linkTo: '/trainingDevelopment' },
                  ]}
                />
              </div>
            </Container>
          </section>
        </div>

        <div className="body-bg">
          <AnimateIn tag="section" className="pt-5 mb-5">
            <Container className="text-center">
              <h1 className="heading my-0">News & Events</h1>

              <div className="hero-opacity my-2">
                <SmallBar style={{ width: '30%' }} />
              </div>

              <div style={{ height: 385 }}>
                <Splide aria-label="Aviation Safety Solutions News" options={splideOptions}>
                  {
                    homeData.news.map((newsItem, i) => (
                      <SplideSlide key={i}>
                        <NewsItem date={newsItem.eventDate} title={newsItem.description} content="" link={newsItem.link} thumbnailSrc={newsItem.image} />
                      </SplideSlide>
                    ))
                  }
                </Splide>
              </div>
            </Container>
          </AnimateIn>

          <div className="hero-opacity my-1 text-center">
            <LargeBar style={{ width: '60%' }} />
          </div>

          <AnimateIn tag="section" className="pt-5">
            <PartnersFooter />
          </AnimateIn>

          <div className="hero-opacity mt-3 pb-5 text-center text-secondary">
            <FlexRow justifyCenter childSpacingX={1}>
              <i className="mdi mdi-36px mdi-star" />
              <i className="mdi mdi-36px mdi-star" />
              <i className="mdi mdi-36px mdi-star" />
              <i className="mdi mdi-36px mdi-star" />
              <i className="mdi mdi-36px mdi-star" />
            </FlexRow>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default () => <IndexPageInternal {...homeDataJson} />;
