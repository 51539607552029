import _ from 'lodash';
import { useMemo } from 'react';
import useWindowSize from './useWindowSize';

interface BreakpointsProps {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
}

const useWindowBreakpoints = (): BreakpointsProps => {
  const { width } = useWindowSize();

  const breakpoints = {
    xs: !!width && width < 576,
    sm: !!width && (width >= 576 && width < 768),
    md: !!width && (width >= 768 && width < 992),
    lg: !!width && (width >= 992 && width < 1200),
    xl: !!width && (width >= 1200),
  };

  return useMemo(() => {
    return breakpoints;
  }, _.values(breakpoints));
};

export default useWindowBreakpoints;
