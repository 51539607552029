import React, { ReactNode } from 'react';
import classnames from 'classnames';

import Diamond from './Diamond';

interface Props {
  label: ReactNode;
  className?: string;
  icon?: ReactNode;
  diamondNumber: 1 | 2 | 3 | 4 | 5;
  backgroundImage: string;
  linkTo?: string;
}

const HoneycombDiamond = ({ label, icon, backgroundImage, diamondNumber, linkTo, className }: Props) => {
  return (
    <Diamond tag="li" className={classnames(`item${diamondNumber}`, className)} backgroundImage={backgroundImage} label={label} icon={icon} linkTo={linkTo}/>
  );
};

export default HoneycombDiamond;
