import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Howl } from 'howler';

const useAutoplayAudio = (...src: string[]): [ boolean, () => void ] => {
  const audioIdRef = React.useRef<number>();
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const howlRef = React.useRef(new Howl({
    src: src
  }));

  useEffect(() => {
    if(_.isNil(audioIdRef.current)) {
      return;
    }

    if(isPlayingAudio) {
      howlRef.current.mute(false, audioIdRef.current);
    } else {
      howlRef.current.mute(true, audioIdRef.current);
    }
  }, [isPlayingAudio]);

  useEffect(() => {
    audioIdRef.current = howlRef.current.play();
    if(!isPlayingAudio) {
      howlRef.current.mute(true, audioIdRef.current);
    }

    return () => {
      if(_.isNil(audioIdRef.current)) {
        return;
      }

      howlRef.current.stop(audioIdRef.current);
    }
  }, []);

  return [
    !isPlayingAudio,
    () => {
      setIsPlayingAudio(!isPlayingAudio);
    }
  ]
};

export default useAutoplayAudio;
